import http from "./httpService"
import { anonymousUser, authUrl, client_id, client_secret, grant_type, scope } from "../config.json"
import {
  manageErrors,
  isBrowser,
  initLocalCart,
} from "../utils/functions"
import { localStorageParams } from "../config.json"

http.setJwt(getJwt())

export async function login(username, password, errors) {
  try {
    const { data } = await http.post(authUrl + "oauth/token", {
      username,
      password,
      client_id,
      client_secret,
      grant_type,
      scope,
    })

    if (data.access_token) {
      window.localStorage.setItem(localStorageParams.token, data.access_token)
    }

    return true
  } catch (ex) {
    console.log("ex", ex)
    window.localStorage.removeItem(localStorageParams.token)
    manageErrors(ex, errors)
    const user = { ...anonymousUser }
    return user
  }
}

export function loginWithFacebook(response) {
  window.localStorage.removeItem(localStorageParams.token)
  window.localStorage.setItem("facebookToken", JSON.stringify(response))
}

export function loginWithJwt(jwt) {
  window.localStorage.setItem(localStorageParams.token, jwt)
}

export function logout() {
  window.localStorage.removeItem(localStorageParams.token)
  window.localStorage.removeItem("facebookToken")
  window.localStorage.removeItem(localStorageParams.user)
  initLocalCart()
}

export async function getCurrentUser() {
  try {
    const token =
      isBrowser() && window.localStorage.getItem(localStorageParams.token)
        ? window.localStorage.getItem(localStorageParams.token)
        : null

    let user = window.localStorage.getItem(localStorageParams.user)
    if (user) {
      user = JSON.parse(user)
    } else {
      user = { ...anonymousUser }
      if (token) {
        try {
          const { data } = await http.get(authUrl + "user/me", {
            headers: { Authorization: "Bearer " + token },
          })
          user = data.data
          window.localStorage.setItem(localStorageParams.user, JSON.stringify(user))
        } catch (ex) {
          window.localStorage.removeItem(localStorageParams.token)
          let errors = {}
          if (!manageErrors(ex, errors)) {
            window.location = "/login"
          }
        }
      } else {
        window.location = "/login"
      }
    }

    return user
  } catch (ex) {
    return null
  }
}

export async function register(data, errors) {
  try {
    const userEndpoint = authUrl + "clients/register"
    await http.post(userEndpoint, data)
    return true
  } catch (ex) {
    //manageErrors(ex, errors)
    return false
  }
}

export async function changePassword(email) {
  try {
    await http.post(authUrl + "password/email", { email })
    return true
  } catch (error) {
    return false
  }

}

export async function resendEmail(email) {
  await http.post(authUrl + "email/resend", {
    email,
  })
}

export async function resetPassword(data) {
  const { email, token, password, password_confirmation } = data
  try {
    await http.post(authUrl + "password/reset", {
      email,
      token,
      password,
      password_confirmation,
    })
    return true
  } catch (error) {
    return false
  }
}

export function getJwt() {
  return isBrowser() && window.localStorage.getItem(localStorageParams.token)
    ? window.localStorage.getItem(localStorageParams.token)
    : null
}

export default {
  login,
  loginWithFacebook,
  loginWithJwt,
  logout,
  getCurrentUser,
  getJwt,
  register,
  changePassword,
}

import React, { Component } from "react"
import logo from "../images/logo.png"
import { ToastContainer, toast } from "react-toastify"
import { localStorageParams } from "../config.json"
import { login } from "../services/authService"
import "bootstrap/dist/css/bootstrap.css"
import 'react-toastify/dist/ReactToastify.css';
import "../components/skeinetStyles.css"
import "../components/layout.css"

class Login extends Component {
  state = {
    data: { username: '', password: '' },
    errors: {}
  }

  handleChange = ({ currentTarget: item }) => {
    const data = { ...this.state.data };
    data[item.id] = item.value;
    this.setState({ data });
  };

  submit = async () => {

    // Validation
    const { data } = this.state
    if (data.username === '') {
      toast.error("Non hai specificato l'email")
      return
    }
    if (data.password === '') {
      toast.error("Non hai specificato la password")
      return
    }

    // Login
    const { data: formData, errors } = this.state
    if (await login(formData.username, formData.password, errors)) {
      const token = window.localStorage.getItem(localStorageParams.token)
      if (token) {
        window.location = "/menu"
      } else {
        toast.error("Utente rifiutato dal server")
      }
    } else {
      toast.error("Utente non riconosciuto")
    }


  }

  render() {
    const { data } = this.state
    return (
      <header className="App-header">
        <ToastContainer position="top-center" />
        <img
          src={logo}
          className="c-pointer"
          alt="logo"
          onClick={this.handleClick}
        />
        <div>
          <input type="text" id="username" name="username" value={data.username} className="form-control" placeholder="email..." onChange={this.handleChange} />
        </div>
        <div className="pt-2">
          <input type="password" id="password" name="password" value={data.password} className="form-control" placeholder="password..." onChange={this.handleChange} />
        </div>
        <div className="pt-2">
          <button className="btn btn-danger px-5" onClick={this.submit}><span className="mx-2">Entra</span></button>
        </div>
      </header>
    )
  }

}

export default Login
